<!-- EmojiSelector.vue -->
<template>
  <div class="emoji-selector" v-show="isOpen">
    <div class="emoji-category" v-for="(category, index) in emojiCategories" :key="index">
      <h4>{{ category.title }}</h4>
      <div class="emoji-grid">
        <button 
          v-for="(emoji, emojiIndex) in category.emojis" 
          :key="emojiIndex"
          @click="selectEmoji(emoji)"
          :title="emoji.description"
        >
          {{ emoji.symbol }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
    name: 'EmojiSelector',
  data() {
    return {
      isOpen: false,
      // 假设你有一个包含多个表情分类和对应表情符号的数据结构
      emojiCategories: [
        {
          title: '笑脸',
          emojis: [
            { symbol: '😀', description: '大笑' },
            { symbol: '😃', description: '开心' },
            // ... 更多表情
          ],
        },
        // ... 更多表情分类
      ],
    };
  },
  methods: {
    selectEmoji(emoji) {
      this.$emit('select', emoji.symbol);
      this.isOpen = false;
    },
  },
};
</script>

<style scoped>
.emoji-selector {
  position: absolute;
  bottom: 100%;
  left: 0;
  width: 100%;
  background-color: #fff;
  border-top: 1px solid #ccc;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);

  display: none; /* 默认隐藏，可以通过v-show在打开时显示 */
}

/* 当父组件触发显示时，可以使用CSS动画或Vue transition来实现弹出效果 */

.emoji-category {
  margin-bottom: 10px;
}

.emoji-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(32px, 1fr));
  grid-gap: 8px;
  padding: 8px;
}

button {
  font-size: 24px;
  line-height: 1;
  cursor: pointer;
  border: none;
  background-color: transparent;
  outline: none;
}
</style>