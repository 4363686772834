<template>
  <div class="chat-page">
    <!-- 聊天头部区域 -->
    <header>
      <user-avatar :user="currentChatUser" />
      <span class="username">{{ currentChatUser.name }}</span>
    </header>

    <!-- 消息列表区域 -->
    <message-list :messages="messages" :myUserId="currentChatUser.id"/>

    <!-- 输入框及发送按钮区域 -->
    <footer class="footer">
      <div class="input-area">
        <emoji-selector @select="handleEmojiSelect" />
        <textarea class="textarea" :value="inputText" placeholder="请输入消息..." @keyup.enter="sendMessage"></textarea>
      </div>
      <button @click="sendMessage">发送</button>
    </footer>
  </div>
</template>

<script>
import MessageList from './MessageList.vue';
import UserAvatar from './UserAvatar.vue';
import EmojiSelector from './EmojiSelector.vue';

export default {
  name: 'ChatPage',
  components: {
    MessageList,
    UserAvatar,
    EmojiSelector,
  },
  data() {
    return {
      messages: [], // 消息数组，每个元素代表一条消息
      inputText: '', // 输入框内容
      currentChatUser: { // 当前聊天对象
        id: '1',
        name: 'Ben',
        avatar: '../assets/default-avatar.png',
      },
    };
  },
  methods: {
    handleEmojiSelect(emoji) {
      this.inputText += emoji;
    },
    sendMessage() {
      if (this.inputText.trim()) {
        // 发送消息逻辑，例如将消息添加到messages数组，并清空输入框
        this.messages.push({
          content: this.inputText,
          sender: this.currentChatUser,
          timestamp: new Date().getTime(),
        });
        this.inputText = '';
      }
    },
  },
};
</script>

<style scoped>
.chat-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100vh;
}

.footer {
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
  padding: 16px;
  background-color: #f8f9fa; /* 选择一个适合你设计的颜色 */
}

.input-area {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  max-width: 320px; /* 根据需求设置最大宽度 */
  margin-bottom: 8px;
  background-image: url('../assets/texture.jpg'); /* 替换为你的纹理图片路径 */
  background-size: cover; /* 或者 background-size: contain; 根据需求调整 */
  background-repeat: no-repeat;
  background-position: center;
}

.emoji-selector,
.textarea {
  border-radius: 4px;
  border: 1px solid #ccc;
  padding: 8px;
  font-size: 14px;
}

.textarea {
  flex-grow: 1;
  min-height: 48px;
  resize: none;
}

button {
  padding: 8px 16px;
  border: none;
  border-radius: 4px;
  background-color: #007bff; /* 蓝色背景颜色，可自定义 */
  color: white;
  font-size: 14px;
  cursor: pointer;
}
</style>