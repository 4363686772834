<template>
    <!-- <HelloWorld msg="Welcome to Your Vue.js App"/> -->
    <chat-page />
</template>

<script>
// import HelloWorld from './components/HelloWorld.vue'
import ChatPage from './components/ChatPage.vue';

export default {
  name: 'App',
  components: {
    // HelloWorld,
    ChatPage
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 0px;
}
</style>
