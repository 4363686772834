<!-- MessageBubble.vue -->
<template>
  <div class="message-bubble" :class="{ 'self': isSelf }">
    <div class="message-bubble__header">
      <user-avatar :user="message.sender" />
      <span class="message-bubble__timestamp">{{ formattedTimestamp }}</span>
    </div>
    <div class="message-bubble__content">
      <!-- 根据消息类型渲染不同内容，这里假设只有文本消息 -->
      <p v-if="isTextMessage">{{ message.content }}</p>
      <!-- 可以添加对图片、文件、链接等其他类型消息的支持 -->
      <!-- <img v-if="isImageMessage" :src="message.imageUrl" alt="Image message" /> -->
    </div>
  </div>
</template>

<script>
import UserAvatar from './UserAvatar.vue';

export default {
  components: {
    UserAvatar,
  },
  props: {
    message: {
      type: Object,
      required: true,
    },
    // 当前用户的ID，用于判断消息发送者是否是当前用户
    myUserId: {
      type: String,
      required: true,
    },
  },
  computed: {
    isSelf() {
      return this.message.sender.id === this.myUserId;
    },
    formattedTimestamp() {
      const date = new Date(this.message.timestamp);
      return date.toLocaleString();
    },
    // 这里仅处理文本消息，你可以根据实际需求添加更多类型的消息判断
    isTextMessage() {
      return typeof this.message.content === 'string';
    },
    // isImageMessage() {
    //   return this.message.type === 'image' && !!this.message.imageUrl;
    // }
  },
};
</script>

<style scoped>
.message-bubble {
  display: flex;
  align-items: flex-start;
  max-width: 75%;
  margin-bottom: 16px;
  padding: 12px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

  &.self {
    align-items: flex-end;
    background-color: #f0f0f0;
  }

  .message-bubble__header {
    display: flex;
    align-items: center;
    margin-bottom: 8px;
  }

  .message-bubble__timestamp {
    font-size: 12px;
    color: #999;
    margin-left: 8px;
  }

  .message-bubble__content {
    /* ... 根据消息类型设置样式 */
  }
}
</style>