<!-- UserAvatar.vue -->
<template>
  <div class="user-avatar">
    <img 
      src="../assets/default-avatar.png" 
      :alt="userName"
      @error="handleImageError"
    />
    <!-- 如果有用户头像未加载成功，显示用户名首字母作为默认头像 -->
    <span v-if="!isImageLoaded" class="initials">{{ initials }}</span>
  </div>
</template>

<script>
export default {
    name: 'UserAvatar',
    // props: {
    //   user: {
    //     type: Object,
    //     required: true,
    //     default: () => ({ name: '', avatar: '' }),
    //   },
    // },
    // data() {
    //   return {
    //     isImageLoaded: false,
    //     initials: '',
    //   };
  props: {
    user: {
      type: Object,
      required: true,
      default: () => ({ name: '', avatar: '' }),
    },
  },
  data() {
    return {
      isImageLoaded: false,
      initials: '',
    };
  },
  computed: {
    userName() {
      return this.user.name;
    },
    avatarUrl() {
        console.log(this.user.avatar);
      return this.user.avatar || '../assets/default-avatar.png'; // 提供默认头像图片地址
    },
  },
  mounted() {
    this.setInitials();
  },
  methods: {
    setInitials() {
        console.log(this.user);
      this.initials = this.userName.split(' ').map(word => word.charAt(0).toUpperCase()).join('');
    },
    handleImageError() {
      this.isImageLoaded = false;
    },
    handleImageLoad() {
      this.isImageLoaded = true;
    },
  },
  updated() {
    const imgElement = this.$el.querySelector('img');
    if (imgElement) {
      imgElement.addEventListener('load', this.handleImageLoad);
      imgElement.addEventListener('error', this.handleImageError);
    }
  },
};
</script>

<style scoped>
.user-avatar {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  overflow: hidden;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.user-avatar img {
  width: 100%;
  height: auto;
  object-fit: cover;
}

.user-avatar .initials {
  font-size: 24px;
  line-height: 48px;
  color: #ccc;
}
</style>