<!-- MessageList.vue -->
<template>
    <div class="message-list" ref="messageList">
      <transition-group name="message-fade" tag="ul">
        <li v-for="(message, index) in messages" :key="index" :class="{ 'self': message.sender === myUserId }">
          <message-bubble :message="message" :myUserId="myUserId" />
        </li>
      </transition-group>
    </div>
  </template>
  
  <script>
  import MessageBubble from './MessageBubble.vue';
  
  export default {
    name: 'MessageList',
    components: {
      MessageBubble,
    },
    props: {
      messages: { type: Array, required: true }, // 消息数组
      myUserId: { type: String, required: true }, // 当前用户的ID
    },
    mounted() {
      this.scrollToBottom();
    },
    updated() {
      this.scrollToBottom();
    },
    methods: {
      scrollToBottom() {
        this.$refs.messageList.scrollTop = this.$refs.messageList.scrollHeight;
      },
    },
  };
  </script>
  
  <style scoped>
  .message-list {
    height: calc(100vh - 80px); /* 根据实际情况调整高度 */
    overflow-y: auto;
    padding: 16px;
  }
  
  .message-fade-enter-active,
  .message-fade-leave-active {
    transition: all 0.3s ease;
  }
  
  .message-fade-enter,
  .message-fade-leave-to {
    opacity: 0;
    transform: translateY(-10px);
  }
  
  /* 自定义不同用户的消息样式 */
  .self {
    text-align: right;
  }
  </style>